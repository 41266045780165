<template>
  <div class="bodys">
    <div class="cont">
      <div class="cont-right">
        <div class="cont-center">
          <div class="cont_title">我要取款</div>
          <div class="yue">
            <div class="yue_1">可用余额：</div>
            <img src="../../../../assets/img/coin.png" class="coin" alt="" />
            <div class="yue_2">{{ balance / 100 }}元</div>
          </div>
          <div class="rech_flex">
            <div class="rech">取款金额:</div>
            <input
              type="text"
              placeholder="请输入提现金额"
              v-model="moneyValue"
              class="money"
              autocomplete="off"
              @input="formatValue(moneyValue)"
            />
          </div>
          <div class="rech_flex" @click="card_x">
            <div class="rech">选择银行卡:</div>
            <input
              type="text"
              readonly
              placeholder="请选择银行卡"
              v-model="kaValue"
              class="money"
              style="cursor: pointer"
            />
            <img
              src="../../../../assets/img/xialaka.png"
              class="xuanka"
              alt=""
            />
            <div class="sel_ka" v-show="card_sel">
              <div
                class="card"
                v-for="(item, index) in userBank"
                :key="index"
                @click.stop="card(item.id, item.bank, item.bank_num)"
              >
                {{ item.bank }} {{ item.bank_num }}
              </div>
            </div>
          </div>
          <div class="rech_flex">
            <div class="rech">资金密码:</div>
            <input
              type="password"
              placeholder="请输入资金密码"
              v-model="pwdValue"
              class="money"
            />
          </div>
          <div class="binds">
            <button class="btns" @click="btns">提现</button>
            <button class="btns2" @click="btns2">提现记录</button>
          </div>
          <button class="add_ka" @click="adds_ka">添加银行卡</button>
        </div>
      </div>
    </div>

    <!-- 遮罩层 -->
    <div class="zhe" v-show="zhe"></div>
    <!-- 添加银行卡 弹窗 -->
    <div class="add" v-show="add_ka">
      <div class="add_title">添加银行卡</div>
      <div class="add_list">
        <div class="lists">
          <div class="lists_name">开户人:</div>
          <input
            type="text"
            class="lists_inp"
            readonly
            :value="account_holder"
            @focus="focusName($event)"
          />
        </div>
        <div class="lists">
          <div class="lists_name">银行卡号:</div>
          <input
            type="text"
            maxlength="19"
            v-model="bank_num"
            class="lists_inp"
            placeholder="请输入银行卡号"
          />
        </div>
        <div class="lists" @click="next_ka">
          <div class="lists_name">所属银行:</div>
          <input
            type="text"
            class="lists_inp"
            :value="bank"
            readonly
            placeholder="点击选择"
            style="cursor: pointer"
          />
          <img
            src="../../../../assets/img/next_ka.png"
            class="next_ka"
            v-show="bank == ''"
            alt=""
          />
          <div class="lists_tan" v-show="lists_tan">
            <div
              class="lists_add_ka"
              @click.stop="add_kas(item.bank, item.id)"
              v-for="(item, index) in banks"
              :key="index"
            >
              {{ item.bank }}
            </div>
          </div>
        </div>
        <div class="lists">
          <div class="lists_name">确认卡号:</div>
          <input
            type="text"
            maxlength="19"
            v-model="bank_num_confirm"
            class="lists_inp"
            placeholder="请输入银行卡号"
          />
        </div>
        <div class="lists">
          <div class="lists_name">开户行:</div>
          <input
            type="text"
            v-model="bankAccount"
            class="lists_inp"
            placeholder="请输入开户行"
          />
        </div>
      </div>
      <div class="lists_bind">
        <button class="bind_ok" @click.stop="bind_ok">确定</button>
        <button class="bind_no" @click.stop="bind_no">取消</button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getWithdraw,
  withdraw,
  addBankCard,
  getBanks,
} from "@network/withdraw";

export default {
  name: "Withdraw",
  data() {
    return {
      moneyValue: "",
      kaValue: "",
      pwdValue: "",
      card_sel: false,
      zhe: false,
      add_ka: false,
      lists_tan: false,
      userBank: [], //用户银行卡
      balance: "0", //可用余额
      account_holder: "", //开户人
      bank: "", //银行
      bank_id: "", //银行id
      bank_num: "", //银行卡号
      bank_num_confirm: "", //确认卡号
      bankAccount: "", //开户行
      bandId: "",
      banks: [], //银行列表
    };
  },
  created() {
    this.getWithdraw();
    this.getBanks();
  },
  components: {},

  methods: {
    //使开户人不能获取焦点
    focusName(event) {
      event.target.blur();
    },
    //获取可以添加的银行列表
    getBanks() {
      getBanks().then((res) => {
        console.log(res);
        if (res.code != 0) {
          return false;
        }
        this.banks = res.data;
      });
    },
    //提现查询
    getWithdraw() {
      getWithdraw().then((res) => {
        console.log(res);
        if (res.code != 0) {
          if (res.code == 403) {
            this.$storage.remove("token");
            this.$message.error({ message: "请重新登录", offset: 300 });
            this.$router.replace("/home");
            return false;
          }
          this.$message.error({ message: res.msg, offset: 300 });
          if (res.code == 501) {
            this.$router.replace("/vipCenter/realNameVerification");
          }
          return false;
        } else {
          this.balance = res[0].user.balance;
          this.account_holder = res[0].user.auth_name;
          if (res[0].userBank.length == 0) {
            this.$message.error({ message: "请先绑定银行卡", offset: 300 });
            this.adds_ka();
            return false;
          }
          this.userBank = res[0].userBank;
        }
      });
    },
    // 显示弹窗 添加银行卡
    adds_ka() {
      this.zhe = true;
      this.add_ka = true;
    },
    //添加银行卡 确定
    bind_ok() {
      if (this.bank == "") {
        this.$message.error({ message: "请选择银行", offset: 300 });
        return false;
      } else if (this.bank_num == "") {
        this.$message.error({ message: "请输入银行卡号", offset: 300 });
        return false;
      } else if (this.bank_num_confirm == "") {
        this.$message.error({ message: "请再次输入银行卡号", offset: 300 });
        return false;
      } else if (this.bankAccount == "") {
        this.$message.error({ message: "请输入开户行", offset: 300 });
        return false;
      } else if (this.bank_num != this.bank_num_confirm) {
        this.$message.error({
          message: "两次输入的银行卡号不一致",
          offset: 300,
        });
        return false;
      }
      addBankCard(this.bank_id, this.bank_num_confirm, this.bankAccount).then(
        (res) => {
          console.log(res);

          if (res.code != 0) {
            this.$message.error({
              message: res.msg,
              offset: 300,
            });
            return false;
          } else {
            this.$message.success({
              message: res.msg,
              offset: 300,
            });
            this.getWithdraw();
            this.zhe = false;
            this.add_ka = false;
          }
        }
      );
    },
    bind_no() {
      this.zhe = false;
      this.add_ka = false;
    },
    // 弹窗里 添加银行卡
    next_ka() {
      this.lists_tan = true;
    },
    add_kas(values, id) {
      this.lists_tan = false;
      this.bank = values;
      this.bank_id = id;
    },
    // 显示银行卡
    card_x() {
      this.card_sel = true;
    },
    // 提现
    btns() {
      if (this.moneyValue == "" || parseFloat(this.moneyValue) <= 0) {
        this.$message.error({ message: "请输入提现金额", offset: 300 });
        return false;
      } else if (parseFloat(this.moneyValue) < 100) {
        this.$message.error({ message: "提现金额不能小于100", offset: 300 });
        return false;
      } else if (this.kaValue == "") {
        this.$message.error({ message: "请选择银行卡", offset: 300 });
        return false;
      } else if (this.pwdValue == "") {
        this.$message.error({ message: "请输入资金密码", offset: 300 });
        return false;
      }
      withdraw(this.moneyValue * 100, this.bandId, this.pwdValue).then(
        (res) => {
          console.log(res);
          if (res.code != 0) {
            this.$message.error({ message: res.msg, offset: 300 });
            return false;
          } else {
            this.$message.success({ message: res.msg, offset: 300 });
            this.moneyValue = "";
            this.kaValue = "";
            this.bandId = "";
            this.pwdValue = "";
          }
        }
      );
    },
    btns2() {
      this.$router.push("/vipCenter/withdrawDetailed");
    },
    card(id, band, num) {
      this.kaValue = band + num;
      this.bandId = id;
      this.card_sel = false;
    },
    // 只允许输入金额类型，最大两位小数（如：3.88）
    formatValue(val) {
      val = val.replace(/(^\s*)|(\s*$)/g, "");
      if (!val) return (this.moneyValue = "");
      val = val.replace(/[^\d.]/g, "");
      val = val.replace(/^\./g, "");
      val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
      this.moneyValue = val;
    },
  },
};
</script>
<style lang='less' scoped>
@import "../../../../assets/css/vipCenter/ChildComps/withdraw.less";
</style>
