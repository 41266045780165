import { request } from './request'

// 提现 查询
export function getWithdraw(){
    return request({
        url:"/mine/withdrawal"
    })
}
//提现
export function withdraw(amount,bankId,fundPwd){
    return request({
        method:"post",
        url:"/mine/withdrawal",
        data:{
            amount,bankId,fundPwd
        }
    })
}

//获取银行
export function getBanks(){
    return request({
        url:"/website/bank"
    })
}

//获取提现记录
export function getWithdrawRecord(page, limit, begin, end) {
    return request({
        url: "/mine/withdrawal/record",
        params: {
            page, limit, begin, end
        }
    })
}
//获取提现记录详情
export function getWithdrawRecordInfo(id) {
    return request({
        url: "/mine/withdrawal/info",
        params: {
            id
        }
    })
}
//添加银行卡
export function addBank(){
    return request({
        url:"/mine/withdrawal/bank",
    })
}

export function addBankCard(bank,bank_num,bank_name){
    return request({
        method:"post",
        url:"/mine/withdrawal/bank",
        data:{
            bank,bank_num,bank_name
        }
    })
}